import API from "../../Network/API";
import React, { Component, Fragment, useEffect, useRef, useState, memo } from "react";
import ContentLoader from "react-content-loader"
import { toast } from "react-toastify";
import MessageAction from "./MessageAction";
import { Link, useParams } from "react-router-dom";
import UserChatFile from "./UserChatFile";
import MessageNav from "./MessageNav";
import FileSend from "./FileSend";
import { useDispatch, useSelector } from "react-redux";
import {
    ADD_MESSAGE_SUCCESS,
    INBOX_FETCH_SUCCESS,
    DELETE_TEMP_MESSAGE,
    INBOX_FETCH_ERROR,
    DELETE_MESSAGE,
    RECEIVE_SOCKET_MESSAGE,
    RECEIVE_MESSAGE_LOADING
} from "../../Reducer/userInboxReducer";

import { MESSAGE_FETCH_ECHO } from '../../Reducer/messageReducer'
import CallSendPopup from '../../Props/CallSendPopup';
import NewCallSound from '../../assets/audio/NewCall.mp3';
import { ADD_CHATID_SUCCESS } from "../../Reducer/ChatListId";
import { USER_SELF_FETCH_SUCCESS } from "../../Reducer/UserSelfReducer";
import useTimer from "../../CustomHooks/useTimer";
import { formatTime } from '../../utils';
import ChatBg from '../../assets/img/web_chat_bg.png';
import ImageView from "../Modal/ImageView";
const mimeType = "audio/webm";

let gumStream = null;
let recorder = null;
let audioContext = null;
const UserInbox = (props) => {
    const fileObj = [];
    const fileArray = [];
    const fileArrayAnother = [];
    const fileArrayVideo = [];
    const [demo_data, setData] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }]);
    const [progress, setProgress] = useState(false);
    const [Messages, setMessages] = useState([]);
    const [selfId, setSelfId] = useState(null);
    const [friendId, setFriendId] = useState(null);
    const [data_read, setData_read] = useState(false);
    const [text, setText] = useState("");
    const [files, setFiles] = useState([]);
    const [user, setUser] = useState("");
    const messagesEndRef = useRef(null);
    const textData = useRef();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [file, setFile] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const fileInput = React.useRef();
    const [isLoading, setLoading] = useState(false);
    const [isRecorde, setRecorde] = useState(false);
    const [isTyping, setTyping] = useState(false);
    const [audio_data, setAudioData] = useState([]);

    const self_data = JSON.parse(localStorage.getItem("user_data"));
    let { id } = useParams();
    const [deviceType, setDeviceType] = useState("");
    const [userId, setUserId] = useState(null);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const [activeUsers, setActiveUsers] = useState([]);
    const [typingUser, setTypingUser] = useState(null);
    const [typingTimer, setTypingTimer] = useState(null);
    const [page, setPage] = useState(0);
    const [has_page, setHasPage] = useState(false);

    const [callStart, setCallStart] = useState(false);
    const [playing, setPlaying] = useState(false);

    const [reply_message_enable, setReplyMessageEnable] = useState(false);
    const [reply_message_id, setReplyMessageId] = useState(0);
    const [reply_message, setReplyMessage] = useState(null);
    const [reply_to, setReplyTo] = useState(null);
    const [heightStyle, setHeightStyle] = useState("mesge-area");
    const [is_darg_drop, setIsDragDrop] = useState(false);
    const [darg_drop_file, setDragDrop] = useState(null);

    const audioRef = useRef(new Audio(NewCallSound));

    //audio sent
    const [permission, setPermission] = useState(false);
    const mediaRecorder = useRef(null);
    const [recordingStatus, setRecordingStatus] = useState(false);
    const [recordingPauseStatus, setRecordingPauseStatus] = useState(false);
    const [stream, setStream] = useState(null);
    const [audio, setAudio] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const { timer, isActive, isPaused, handleStart, handlePause, handleResume, handleReset } = useTimer(0)
    const [img_index, setImIndex] = useState(null);
    const [imageView_modalDialog, setImageViewModalDialog] = useState(false);
    const [image_array, setImageArray] = useState([]);

    const toggleModalFileUpload = () => {
        setPostShareModalDialog(!post_modalDialog)
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(USER_SELF_FETCH_SUCCESS(JSON.parse(localStorage.getItem("user_data"))))
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                navigator.userAgent
            )
        ) {
            setDeviceType("Mobile");
        } else {
            setDeviceType("Desktop");
        }
        let timer;

        dispatch(ADD_CHATID_SUCCESS(id))
    }, [id]);

    useEffect(() => {
        setProgress(true)
        API.get(`/users/${id}/conversation`).then(response => {

            if (response.data.data.message_pagination.next_page_url == null) {
                setHasPage(false)
            } else {
                setHasPage(true)
            }

            setSelfId(response.data.data.self)
            const ttt = response.data.data.conversations.slice().reverse();
            const tt = ttt[ttt?.length - 1];


            if (tt != null) {
                let setss = 0;
                if (id == tt.sender.id) {
                    setss = 1;
                }
                const payload = {
                    last_message_id: tt.id,
                    name: setss == 1 ? tt.sender.name : tt.receiver.name,
                    image: setss == 1 ? tt.sender.avatar : tt.receiver.avatar,
                    to_id: setss == 1 ? tt.from_id : tt.to_id,
                    is_group: tt.is_group,
                    message: tt.message,
                    url_details: tt.url_details,
                    seen_at: tt.is_seen ? tt.seen_at : null,
                    last_messages: null,
                    is_online: setss == 1 ? tt.sender.is_online : tt.receiver.is_online,
                    unread_count: 0,
                    created_at: tt.created_at,
                }

                dispatch(MESSAGE_FETCH_ECHO(payload))
            }

            setData_read(true)
            setUser(response.data.data.user)
            setProgress(false)
            dispatch(INBOX_FETCH_SUCCESS(response.data.data))
            setPage(2)
            scrollToBottom()
        });

        API.get(`/friend_file_sharing/${id}`).then(response => {
            setChatHistory(response.data.data.conversations)
        });
        scrollToBottom()

    }, [id]);

    const chatReducer = useSelector((state) => state.chatLogs)
    const chats = chatReducer?.messsage
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }

    const uploadHandler = (e) => {
        setSelectedFile(e.target.files[0]);
        setFileUrl(URL.createObjectURL(e.target.files[0]))
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            let extn = e.target.files[i].name.split('.').pop();

            if (extn.toLowerCase() === 'mp4' || extn.toLowerCase() === 'wmv' || extn.toLowerCase() === 'avi' || extn.toUpperCase() === "MKV" || extn.toUpperCase() === "WEBM" || extn.toUpperCase() === "MOV") {
                fileArrayVideo.push(URL.createObjectURL(fileObj[0][i]));
            } else {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
            }
            fileArrayAnother.push(fileObj[0][i])
        }

        setFile(fileArrayAnother)

    }

    const onClick = () => {
        fileInput.current.click();
    }

    const [isExpand, setIsExpand] = useState(true);

    const toggleExpand = () => {
        setIsExpand(!isExpand);
    };

    const deleteMessageEveryOne = (messageId) => {
        var data = {
            'message_id': messageId,
        }
        API.post(`conversations/${messageId}/delete`, data).then(response => {
            setLoading(false);
            dispatch(DELETE_MESSAGE(messageId))
            toast.success('Message deleted');
        }).catch((error) => {
            setLoading(false);
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedFile === null && text === null) {
            toast.error("Message is empty");
            return false;
        }

        const fd = new FormData();

        if (selectedFile != null) {
            let fileExtention = selectedFile.name.split('.').pop();
            if (fileExtention.toUpperCase() == "MP4" || fileExtention.toUpperCase() == "WMV" || fileExtention.toUpperCase() == "AVI" || fileExtention.toUpperCase() == "MKV" || fileExtention.toUpperCase() == "WEBM" || fileExtention.toUpperCase() == "MOV") {
                if (selectedFile) {
                    fd.append('files[]', selectedFile, selectedFile.name);
                }
            } else {
                if (file) {
                    for (var i = 0; i < file.length; i++) {
                        fd.append('files[]', file[i], file[i].name);
                    }
                }
            }
        }

        const payload = {
            temp_id: id,
            from_id: selfId.id,
            to_id: id,
            seen_at: null,
            message: text,
            sender: { name: selfId.name, avatar: selfId.avatar },
            created_at: "1s ago"
        }

        dispatch(ADD_MESSAGE_SUCCESS(payload))


        fd.append('to_id', id);
        fd.append('message', text);
        if (reply_message_id != 0) {
            fd.append('reply_to', reply_message_id);
        }
        scrollToBottom();
        setLoading(true)
        API.post("/send_chat", fd, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoading(false)
            setReplyMessageEnable(false)
            setReplyMessageId(0)
            if (response.data.success === true) {
                const tt = response.data.data.message;

                const payload = {
                    last_message_id: tt.id,
                    name: tt.receiver.name,
                    image: tt.receiver.avatar,
                    to_id: tt.to_id,
                    is_group: tt.is_group,
                    message: tt.message,
                    url_details: tt.url_details,
                    seen_at: null,
                    last_messages: null,
                    is_online: tt.receiver.is_online,
                    unread_count: 0,
                    created_at: tt.created_at,
                }

                dispatch(MESSAGE_FETCH_ECHO(payload))

                dispatch(DELETE_TEMP_MESSAGE(id))
                dispatch(ADD_MESSAGE_SUCCESS(response.data.data.message))
            } else if (response.data.success === false) {

            }
        })
            .catch((err) => {
                setLoading(false)
            });

        setText("");
        event.target.reset();
        scrollToBottom();
    }

    const handleSubmitEnter = (event) => {
        if (selectedFile === null && text === null) {
            toast.error("Message is empty");
            return false;
        }

        const fd = new FormData();

        if (selectedFile != null) {
            let fileExtention = selectedFile.name.split('.').pop();
            if (fileExtention.toUpperCase() == "MP4" || fileExtention.toUpperCase() == "WMV" || fileExtention.toUpperCase() == "AVI" || fileExtention.toUpperCase() == "MKV" || fileExtention.toUpperCase() == "WEBM" || fileExtention.toUpperCase() == "MOV") {
                if (selectedFile) {
                    fd.append('files[]', selectedFile, selectedFile.name);
                }
            } else {
                if (file) {
                    for (var i = 0; i < file.length; i++) {
                        fd.append('files[]', file[i], file[i].name);
                    }
                }
            }
        }

        const payload = {
            temp_id: id,
            from_id: selfId.id,
            to_id: id,
            seen_at: null,
            message: text,
            sender: { name: selfId.name, avatar: selfId.avatar },
            created_at: "1s ago"
        }

        dispatch(ADD_MESSAGE_SUCCESS(payload))


        fd.append('to_id', id);
        fd.append('message', text);
        if (reply_message_id != 0) {
            fd.append('reply_to', reply_message_id);
        }
        scrollToBottom();
        setLoading(true)
        API.post("/send_chat", fd, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoading(false)
            setReplyMessageEnable(false)
            setReplyMessageId(0)
            if (response.data.success === true) {
                const tt = response.data.data.message;

                const payload = {
                    last_message_id: tt.id,
                    name: tt.receiver.name,
                    image: tt.receiver.avatar,
                    to_id: tt.to_id,
                    is_group: tt.is_group,
                    message: tt.message,
                    url_details: tt.url_details,
                    seen_at: null,
                    last_messages: null,
                    is_online: tt.receiver.is_online,
                    unread_count: 0,
                    created_at: tt.created_at,
                }

                dispatch(MESSAGE_FETCH_ECHO(payload))

                dispatch(DELETE_TEMP_MESSAGE(id))
                dispatch(ADD_MESSAGE_SUCCESS(response.data.data.message))
            } else if (response.data.success === false) {

            }
        })
            .catch((err) => {
                setLoading(false)
            });

        setText("");
        scrollToBottom();
    }

    const onStop = (blob) => {
        console.log("uploading...");

        let data = new FormData();
        data.append('files[]', blob, "recording.wav");

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
    }

    const deleteMessage = (messageId, keyValue) => {
        var data = {
            'message_id': messageId,
        }
        API.post(`conversations/message/${messageId}/delete`, data).then(response => {
            setLoading(false)
            dispatch(DELETE_MESSAGE(messageId))
            toast.success('Message Deleted')
        }).catch((error) => {
            setLoading(false)
        });
    }

    const clickPhoto = () => {
        toggleModalFileUpload();
    }


    const childToParent = (childdata) => {
        scrollToBottom();
        dispatch(DELETE_TEMP_MESSAGE(id))
        dispatch(ADD_MESSAGE_SUCCESS(childdata))
    }

    const handleInput = (e) => {
        const textarea = e.target;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
        setText(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmitEnter();
        }
    };


    function loadMore() {
        setProgress(true)
        API.get(`/users/${id}/conversation?page=${page}`).then(response => {
            setPage((page + 1))

            if (response.data.data.message_pagination.next_page_url != null) {
                setHasPage(true)
            } else {
                setHasPage(false)
            }
            setProgress(false)
            dispatch(RECEIVE_MESSAGE_LOADING(response.data.data))
        });
    }

    const playNewCall = () => {
        setPlaying(true);
        audioRef.current.play();
    };

    const pauseNewCall = () => {
        setPlaying(false);
        audioRef.current.pause();
    };

    function startCall(userId) {
        setCallStart(true);
        playNewCall();
        API.post(`send_calling?user_id=${userId}&type=0`).then((response) => {
            // console.log(response);
        }).catch((error) => {
            console.log(error);
        });

        //hide calling ui after 35 second's
        setTimeout(() => {
            setCallStart(false);
        }, 30000);
    }

    function cancelCall() {
        setCallStart(false);
        pauseNewCall();
    }

    // const [reply_message_enable, setReplyMessageEnable] = useState(false);
    // const [reply_message_id, setReplyMessageId] = useState(0);
    // const [reply_message, setReplyMessage] = useState(null);
    // const [reply_to, setReplyTo] = useState(null);
    const replyMessage = (id, message, username) => {
        setReplyMessageEnable(true)
        setReplyMessageId(id)
        setReplyMessage(message)
        setReplyTo(username);
        setHeightStyle("mesge-area-reply");


    }

    const replyMessageCancel = () => {
        setReplyMessageEnable(false)
        setReplyMessageId(0)
        setReplyMessage(null)
        setReplyTo(null);
        setHeightStyle("mesge-area");
    }

    const getMicrophonePermission = async () => {
        if ("MediaRecorder" in window) {
            try {
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setPermission(true);
                setStream(mediaStream);
            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };

    const startRecording = async () => {
        getMicrophonePermission();
        setRecordingStatus(true);
        handleStart();
        const mediaStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        });
        const media = new window.MediaRecorder(mediaStream, { type: mimeType });

        mediaRecorder.current = media;

        mediaRecorder.current.start();

        let localAudioChunks = [];

        mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
            localAudioChunks.push(event.data);
        };

        setAudioChunks(localAudioChunks);
    };

    const pauseRecording = async () => {
        setRecordingPauseStatus(true);
        handlePause();
        const media = new MediaRecorder(stream, { type: mimeType });
        mediaRecorder.current = media;
        mediaRecorder.current.pause();

    };

    const stopRecording = () => {
        setRecordingStatus(false);
        handleReset();
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            if (mediaRecorder.current.stream) {
                mediaRecorder.current.stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        }

        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: mimeType });
            const audioUrl = URL.createObjectURL(audioBlob);

            let data = new FormData();
            data.append('to_id', id);
            data.append('message', '');
            data.append('files[]', audioBlob, "recording.wav");
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            setLoading(true)
            API.post("/send_chat", data, config).then((response) => {
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
            });

            setAudio(audioUrl);

            setAudioChunks([]);
        };
    };

    const dropZoneRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const imageFile = e.dataTransfer.files[0];
        setDragDrop(e.dataTransfer.files[0])
        setIsDragDrop(!is_darg_drop)
        setPostShareModalDialog(!post_modalDialog)
    }

    const toggleImageView = (value, imgindex) => {
        setImageArray([value]);
        setImIndex(imgindex);
        setImageViewModalDialog(!imageView_modalDialog);
    }

    return (
        <>
            {callStart &&
                <CallSendPopup onCancel={() => cancelCall()} image={user.avatar} name={user.name} />
            }

            <div className="row">
                {deviceType === 'Desktop' ?
                    <>
                        <div className="col-md-3 d-none d-sm-block message-nav-area">
                            <MessageNav />
                        </div>
                    </> :
                    <div className="col-md-12 d-none d-sm-block">
                        <MessageNav />
                    </div>
                }
                <div onDrop={handleDrop} onDragOver={handleDragOver} ref={dropZoneRef} className={isExpand ? 'animated-col col-md-9 margin-top-minex p-0' : 'animated-col col-md-6 margin-top-minex p-0'}>
                    <div className="mesge-area-scroll" style={{ backgroundImage: `url(${ChatBg})` }}>
                        <div className="mesg-area-head d-flex justify-content-between align-items-center shadow-sm" >

                            <div className="active-user d-flex align-items-center">
                                {deviceType === 'Mobile' ?
                                    <Link to={`/messages`}>
                                        <div className="msg-back clickable">

                                            <i className="fa-solid fa-arrow-left" />

                                        </div>
                                    </Link>
                                    : ''}

                                <div className="mesg-area-head-user-avater">
                                    <div
                                        className="user-picture-container rounded-circle"
                                        style={{ background: `url(${user.avatar})` }}
                                    />
                                </div>
                                <div className="msg-avater-info">
                                    <h6 className="unread">{user.name}</h6>
                                    <span>{user.is_online == 1 ? 'Online' : user.last_seen1}</span>
                                </div>
                            </div>
                            <ul className="mesg-head-right">
                                <li className="msg-search">
                                    <i onClick={() => startCall(user.id)} className="fa-solid fa-phone main-color" />
                                </li>
                                <li onClick={toggleExpand}>
                                    <div className="global-dropdown">
                                        <div className="dropdown">
                                            <button
                                                className="dropdown-toggle dropdown-bg-transparent"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i className="fa-solid fa-ellipsis-vertical" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={heightStyle}>

                            <ul className="conversations ps-container ps-theme-default ps-active-y">
                                {has_page == true ?
                                    <div className="text-center">
                                        <button type="button" className="btn btn-primary rounded-1 btn-sm"
                                            onClick={() => loadMore()}>Load More {progress ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}</button>
                                    </div>

                                    : ''}
                                {progress && demo_data?.map((post, index) => (
                                    <>
                                        <li className="you justify-content-start" key={index}>
                                            <ContentLoader
                                                speed={2}
                                                width={400}
                                                height={80}
                                                viewBox="0 0 400 80"
                                                backgroundColor="#eeecec"
                                                foregroundColor="#eae6e6"
                                                {...props}
                                            >
                                                <circle cx="37" cy="47" r="17" />
                                                <rect x="65" y="36" rx="0" ry="0" width="239" height="8" />
                                                <rect x="66" y="50" rx="0" ry="0" width="198" height="7" />
                                            </ContentLoader>
                                        </li>

                                        <li className="me justify-content-end">
                                            <ContentLoader
                                                speed={2}
                                                width={400}
                                                height={80}
                                                viewBox="0 0 400 80"
                                                backgroundColor="#eeecec"
                                                foregroundColor="#eae6e6"
                                                {...props}
                                            >
                                                <circle cx="37" cy="47" r="17" />
                                                <rect x="65" y="36" rx="0" ry="0" width="239" height="8" />
                                                <rect x="66" y="50" rx="0" ry="0" width="198" height="7" />
                                            </ContentLoader>
                                        </li>
                                    </>

                                ))}

                                {!progress &&
                                    chats?.map((value, index) => (
                                        <>
                                            {selfId?.id === value.from_id ?
                                                <li key={value.id}
                                                    className={`me d-flex ${value.is_reply == 1 ? 'justify-content-end' : ''} justify-content-end message_id_${index}`}
                                                    id={`message_id_${value.id}`}>
                                                        <div className={`text-box message-text shadow-sm`}>

                                                            {value.is_reply == 1 ?
                                                                <p className="mb-2 me-text">{value.old_message}</p>
                                                            :""
                                                            }
                                                            {value.file_name != null ?
                                                                <>
                                                                    {value.message_type == 4 ?
                                                                        <div>
                                                                            <audio
                                                                                controls
                                                                                src={value.file_name}>

                                                                            </audio>
                                                                        </div>
                                                                        :
                                                                        value.message_type == 5 ?
                                                                            <div>
                                                                                <video
                                                                                    controls
                                                                                    src={value.file_name}>

                                                                                </video>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <img onClick={() => toggleImageView(value.file_name, 0)} className="d-block message_image rounded-3" src={value.file_name} alt={value.file_name} />
                                                                                <a className="text-white mt-2" target="_blank" href={value.file_name}>Download</a>
                                                                            </div>
                                                                    }
                                                                    <p dir="auto">
                                                                        {value.message}
                                                                    </p>

                                                                    <span> {value.created_at}
                                                                        {
                                                                            value.temp_id ?
                                                                                <i className="fa-solid fa-check-double ms-2" style={{ color: 'rgb(191 183 183)' }} />
                                                                                : value.is_seen != 0 ?
                                                                                    <i className="fa-solid fa-check-double ms-2" />
                                                                                    : <i className="fa-solid fa-check-double ms-2"
                                                                                        style={{ color: 'rgb(255, 255, 255)' }} />
                                                                        }</span>
                                                                </>
                                                            : value.message_type === 10 ?
                                                                    <>
                                                                        <div className="meeting-Invitation w-100">
                                                                            <div className="text-box message-text shadow-sm">
                                                                                <p>
                                                                                    Meeting Link
                                                                                </p>
                                                                                <p className="text-white mt-3">{value.message}</p>
                                                                            </div>
                                                                            <div className="ms-3 mt-2">
                                                                                <a href={value.message} target={'_blank'}
                                                                                    className="btn btn-light btn-sm"><i
                                                                                        className="fas fa-plus me-1" /> Join Now
                                                                                </a>
                                                                            </div>

                                                                        </div>
                                                                        <span> {value.created_at}</span>
                                                                    </>
                                                                :
                                                                value.message_type == 11 && value.url_details ? (
                                                                    <>
                                                                        
                                                                            {value.url_details.url && (
                                                                                <p className="mb-2 text-decoration-underline text-white">
                                                                                    {value.url_details.url}
                                                                                </p>
                                                                            )}
                                                                            <a href={value.url_details.url} target="_blank">
                                                                                <div className="link-msg-img">
                                                                                    <img className="" src={value.url_details.image} alt="" />
                                                                                </div>
                                                                                <p className="mt-2 lh-1 link-msg-dsc">{value.url_details.title}</p>
                                                                                <p className="mt-2 lh-1 link-msg-dsc">
                                                                                    {value.url_details.description}
                                                                                </p>
                                                                            </a>

                                                                            <span> 
                                                                                {value.created_at}
                                                                                {
                                                                                    value.temp_id ?
                                                                                            <i className="fa-solid fa-check-double ms-2" style={{ color: 'rgb(191, 183, 183)' }} />
                                                                                        : value.is_seen != 0 ?
                                                                                            <i className="fa-solid fa-check-double ms-2" />
                                                                                        :
                                                                                            <i className="fa-solid fa-check-double ms-2" style={{ color: 'rgb(255, 255, 255)' }} />
                                                                                }
                                                                            </span>
                                                                        
                                                                    </>
                                                                ) :
                                                                    <>
                                                                        <p dir="auto">
                                                                            {value.message}
                                                                        </p>
                                                                        <span> 
                                                                            {value.created_at}
                                                                            {
                                                                                value.temp_id ?
                                                                                        <i className="fa-solid fa-check-double ms-2" style={{ color: 'rgb(191, 183, 183)' }} />
                                                                                    : value.is_seen != 0 ?
                                                                                        <i className="fa-solid fa-check-double ms-2" />
                                                                                    :
                                                                                        <i className="fa-solid fa-check-double ms-2" style={{ color: 'rgb(255, 255, 255)' }} />
                                                                            }
                                                                        </span>
                                                                    </>
                                                            }

                                                        </div>
                                                    
                                                        <div className="chat-dropEnd">
                                                            <div className="btn-group dropstart">
                                                                <button type="button" className="btn p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                                                </button>
                                                                <ul className="dropdown-menu" >
                                                                    <li>
                                                                        <a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => replyMessage(value.id, value.message, value.sender.name)}>
                                                                            <i className="fa-solid  me-2"></i> Reply</a>
                                                                    </li>
                                                                    <li>
                                                                        <a className="dropdown-item d-flex align-items-center clickable" onClick={() => deleteMessageEveryOne(value.id)}>
                                                                            <i className="fa-solid me-2" /> Delete for everyone</a>
                                                                    </li>
                                                                    <li>
                                                                        <a className="dropdown-item d-flex align-items-center clickable" onClick={() => deleteMessage(value.id, index)}>
                                                                            <i className="fa-solid  me-2"></i> Delete Message</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    

                                                </li>
                                                :
                                                <li key={value.id} className="you d-flex justify-content-start">
                                                    <figure>
                                                        <div
                                                            className="user-picture-container rounded-circle me-2"
                                                            style={{ background: `url(${value.sender.avatar})` }}
                                                        />
                                                    </figure>
                                                    <div className="chat-dropEnd">
                                                        <div className="btn-group dropend">
                                                            <button type="button" className="btn   p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                                            </button>
                                                            <ul className="dropdown-menu" >
                                                                <li>
                                                                    <a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => replyMessage(value.id, value.message, value.sender.name)}>
                                                                        <i className="fa-solid  me-2"></i>Reply</a>
                                                                </li>
                                                                <li>
                                                                    <a className="dropdown-item d-flex align-items-center " onClick={() => deleteMessage(value.id, index)} href="javascript:void(0)">
                                                                        <i className="fa-solid me-2"></i>Delete Message</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="meeting-Invitation w-100">
                                                        <div className="text-box message-text shadow-sm">
                                                            {value.file_name != null ?
                                                                <>
                                                                    <MessageAction value={value} />
                                                                    <p dir="auto">
                                                                        {value.message}

                                                                        {value.message_type == 4 ?
                                                                            <div>
                                                                                <audio
                                                                                    controls
                                                                                    src={value.file_name}>

                                                                                </audio>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <img onClick={() => toggleImageView(value.file_name, 0)} className="d-block message_image" src={value.file_name} alt={value.file_name} />
                                                                                <a className="text-primary mt-1" target="_blank" href={value.file_name}>Download</a>
                                                                            </div>
                                                                        }
                                                                    </p>
                                                                    {value.url_details != null ?
                                                                        <>
                                                                            <a href={value.url_details['url']} target="_blank">
                                                                                <div className="link-msg-img">
                                                                                    <img onClick={() => toggleImageView(value.url_details['url'], 0)} className="clickable"
                                                                                        src={value.url_details['image']}
                                                                                        alt="" />
                                                                                </div>
                                                                                <p className="mt-2 link-msg-title">{value.url_details['title']}</p>
                                                                                <p className="mt-2 link-msg-dsc">{value.url_details['description']}</p>
                                                                            </a>
                                                                        </>
                                                                        :
                                                                        ""
                                                                    }

                                                                    <span> {value.created_at}</span>
                                                                </>
                                                                : value.message_type === 10 ?
                                                                    <>
                                                                        <div className=" meeting-Invitation w-100">
                                                                            <div className="text-box message-text shadow-sm">
                                                                                <h6 className="mb-1">Invitation for Meeting</h6>
                                                                                <p>
                                                                                    Please Join using this link or click to join
                                                                                    now
                                                                                </p>
                                                                                <p className="text-primary mt-3">{value.message}</p>
                                                                            </div>
                                                                            <div className="ms-3 mt-2">
                                                                                <a href={value.message} target={'_blank'}
                                                                                    className="btn btn-primary btn-sm"><i
                                                                                        className="fas fa-plus me-1" /> Join Now
                                                                                </a>
                                                                            </div>

                                                                        </div>
                                                                        <span> {value.created_at}</span>
                                                                    </>
                                                                    :

                                                                    <>
                                                                        {value.is_reply == 1 ?
                                                                            <p className="mb-2 you-text">{value.old_message}</p>
                                                                            : ''}
                                                                        <p dir="auto" className={`${value.is_reply == 1 ? 'replay-text' : ''}`}>
                                                                            {value.message}

                                                                        </p>
                                                                        {value.url_details != null ?
                                                                            <>
                                                                                <a href={value.url_details['url']}
                                                                                    target="_blank">
                                                                                    <div className="mt-2 link-msg-img">
                                                                                        <img className=""
                                                                                            src={value.url_details['image']}
                                                                                            alt="" />
                                                                                    </div>
                                                                                    <p className="mt-2 link-msg-title">{value.url_details['title']}</p>
                                                                                    <p className="mt-2 link-msg-dsc">{value.url_details['description']}</p>
                                                                                </a>
                                                                            </>
                                                                            :
                                                                            ""
                                                                        }
                                                                        <span> {value.created_at}

                                                                        </span>
                                                                    </>

                                                            }

                                                        </div>
                                                    </div>
                                                </li>
                                            }

                                        </>

                                    ))}

                            </ul>
                            <div className="messageEndArea" ref={messagesEndRef} />

                        </div>
                    </div>

                    {reply_message_enable ?
                        <div className="text-reply">
                            <div className="d-flex reply-flex">
                                <div className="text-reply-head">
                                    <span className="replying d-block">{reply_to}</span>
                                    <p className="replying-text">{reply_message}</p>
                                </div>
                                <div className="text-reply-cancel">
                                    <a href="javascript:void(0)" onClick={() => replyMessageCancel()}><i className="fa-solid fa-xmark"></i></a>
                                </div>

                            </div>
                        </div>
                        : ''}

                    <div className={isExpand ? 'message-writing-box col-9' : 'message-writing-box col-6'}>
                        <form method="post" onSubmit={handleSubmit} >
                            <div className="text-area row flex-nowrap">
                                {recordingStatus ?

                                    <input type="text" className="col-10" disabled={true}
                                        placeholder={formatTime(timer)}
                                    />
                                    :
                                    <div className="message-input-area col-10">
                                        <textarea
                                            className="form-control"
                                            placeholder={""}
                                            value={text}
                                            onInput={handleInput}
                                            onKeyDown={handleKeyDown}
                                            onChange={(e) => {
                                                setText(e.target.value);
                                            }}
                                            rows="1"
                                            style={{
                                                overflow: "hidden",
                                            }}
                                        />
                                    </div>
                                }
                                <div className="col-2 d-flex message-writing-box-btn align-items-center justify-content-between">
                                    <div className="voice-record">
                                        {recordingStatus ?
                                            <a className={'clickable'} onClick={stopRecording}>
                                                <i className="fa-solid fa-stop text-danger" />
                                            </a>
                                            :
                                            <a className={'clickable'} onClick={startRecording}>
                                                <i className="fa-solid fa-microphone" />
                                            </a>
                                        }


                                    </div>
                                    <div className="attach-file">
                                        <label className="fileContainer clickable" onClick={() => clickPhoto()}>
                                            <i className="fa-solid fa-image" />
                                            <input type="hidden" multiple={true} accept="audio/*,video/*,image/*"
                                                onClick={() => clickPhoto()} />
                                        </label>
                                    </div>
                                    <button type="submit">
                                        <i className="fa-solid fa-paper-plane" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={isExpand ? 'd-none' : 'col-md-3 d-none d-sm-block g-0'}>
                    <UserChatFile user={user} recentChatFile={chatHistory} self_data={self_data} />
                </div>
            </div>
            <FileSend dargDrop={is_darg_drop} dargDropFile={darg_drop_file} isModalVisible={post_modalDialog} handleShowB={() => setPostShareModalDialog(false)} to_id={id}
                childToParent={childToParent} is_group='0' />

            <ImageView images={image_array} isModalVisible={imageView_modalDialog} handleShowB={() => setImageViewModalDialog(false)} imageIndex={img_index} />

        </>)
}
export default UserInbox;
/* eslint-disable */

import MobileNav from "../Partials/MobileNav";
import Load from "../Load";
import MeetingJoinSound from '../../assets/audio/meeting_join.wav';
import React, { Component, Fragment, useState, useEffect } from "react";
import API from "../../Network/API";
import { useNavigate, BrowserRouter as Router, Redirect, Switch, Route, Routes, Link, useParams } from "react-router-dom";
import { toast } from 'react-toastify';

toast.configure();

export default function MeetingRoom() {
    let { room } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const containerStyle = {
        width: '100%',
        height: '100%',
        background: '#000',
    };

    const playSound = () => {
        const audio = new Audio(MeetingJoinSound);
        audio.addEventListener('canplaythrough', (event) => {
            audio.play();
        });
    };


    useEffect(() => {
        API.get(`/join/` + room).then(response => {
            if (response.data.room !== "") {
                startConference(response.data.jwt, room);
            }
            if (response.data.error_code === "406") {
                API.get('/meeting/' + room).then(res => {
                    window.location.href = "/meeting/" + room;
                }).catch((e) => {
                    window.location.href = "/meeting-waiting/" + room;
                });

            }
            if (response.data.error_code === '404') {
                toast.error('Invalid meeting id', {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => window.location.href = "/meeting"
                });
            }

        }).catch((err) => {
            toast.error('Invalid meeting', {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                onClose: () => window.location.href = "/meeting"
            });


        });
    }, []);

    function startConference(_token, room) {

        try {
            const domain = 'eu-west-21.connectsweb.com';
            const options = {
                roomName: room,
                jwt: _token,
                height: '100%',
                parentNode: document.getElementById('meeting-container'),
            };

            const api = new JitsiMeetExternalAPI(domain, options);

            setLoading(false);
            playSound();

            api.addEventListener(
                'videoConferenceLeft', () => {
                    navigate('/meeting');
                },
                'videoConferenceJoined', () => {
                }
            );
            api.getCurrentDevices().then(devices => {
                setLoading(false)
            });

        } catch (error) {
        }
    }

    localStorage.setItem("set_meeting_url", null);
    if (localStorage.getItem("connect_token") === 'undefined' || localStorage.getItem("connect_token") == null) {
        localStorage.setItem("set_meeting_url", window.location.href);
    }
    
    return (
        <Fragment>
            <div className="content-page-box-area">
                <div className="row">
                    <div style={containerStyle}>
                        {loading ? <Load /> : ""}
                        <div id="meeting-container" className="meetingContainerStyle" />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}